import React, { useState } from 'react';
import { format } from 'date-fns';
//import Header from '../myf-cilent-header/header.js';
import API from '../myf-api/api.js';
import ExtraVehicleInvestment from '../myf-cilent-vehicle-management/extraVehicleInvestment.js';
import Loader from '../components/loader.js';
import '../myf-cilent-customer-pendinglist/customerPopUp.css';

const AddPayment = () => {
    const [fileNumber, setFileNumber] = useState('');
    const [customer, setCustomer] = useState(null);
    const [updatedPayments, setUpdatedPayments] = useState([]);
    const [vehicleInvestment, setVehicleInvestment] = useState([]);
    const [loading, setLoading] = useState(false);
    const url = API();
    const [isPopupVisible, setPopupVisible] = useState(false);
    const token = localStorage.getItem('accessToken');

    const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    const onClose = () => {
        setPopupVisible(false);
        //handleSubmit();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(`${url}/api/addPaymentDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ fileNumber }),
            });
            const data = await response.json();
            setCustomer(data.customer);
            setUpdatedPayments(data.updatedPayments);

            const extraVehicleInvestmentRes = await fetch(`${url}/addExtraVehicleInvestment?fileNumber=${fileNumber}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            const vehicleInvestment = await extraVehicleInvestmentRes.json();
            setVehicleInvestment(vehicleInvestment);

            setLoading(false);
        } catch (error) {
            window.alert('Error fetching data:', error);
        }
    };

    document.title = 'Add Payment - Our Finance';

    return (
        <div>
            {/* <Header /> */}

            <h2>Add Customer Payment Details</h2>

            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="fileNumber"
                    placeholder="Enter File number"
                    value={fileNumber}
                    onChange={(e) => setFileNumber(e.target.value)}
                />
                <button type="submit">Submit</button>
            </form>

            {loading ? <Loader /> :
                customer && (
                    <div>
                        <h2>Customer Details</h2>
                        <p>File Number: {customer.fileNumber}</p>
                        <p>First Name: {customer.firstname}</p>
                        <p>Amount: Rs: {customer.amount}/-</p>
                        <p>Installments: {customer.installments}</p>

                        <div>
                            <h2>Vehicle Investment</h2>
                            {vehicleInvestment.length>0 && (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Type of Money</th>
                                            <th>Purpose Of Money</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Receipt Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vehicleInvestment.map((investment,index) => (
                                            <tr key={investment._id}>
                                                <td>{index+1}</td>
                                                <td>{investment.typeOfMoney}</td>
                                                <td>{investment.purposeOfMoney}</td>
                                                <td>{format(new Date(investment.date), 'dd-MM-yyyy')}</td>
                                                <td>Rs:{investment.amount}/-</td>
                                                <td>{investment.receiptNumber}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <br></br>
                            <button onClick={togglePopup}>Pay Investment</button>

                            {isPopupVisible && (
                                <div className="popup-container">
                                    <div className="popup-content">
                                        <ExtraVehicleInvestment page="addPayment" fileNumber = {customer.fileNumber} bikeDetails = {customer.bikeModel}/>
                                        <br />
                                        <button onClick={onClose}>Close</button>
                                    </div>
                                </div>
                            )}

                        </div>

                        <h2>Installment Schedule</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Installment Number</th>
                                    <th>Month</th>
                                    <th>Due Date</th>
                                    <th>Installment Amount</th>
                                    <th>Paid Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {updatedPayments.map((payment) => (
                                    <tr key={payment.installmentNum}>
                                        <td>{payment.installmentNum}</td>
                                        <td>{payment.month}</td>
                                        <td>{format(new Date(payment.dueDate), 'dd-MM-yyyy')}</td>
                                        <td>Rs: {payment.installmentAmount}/-</td>
                                        <td>{payment.payments.map((date) => (
                                            format(new Date(date.dateOfPaid), 'dd-MM-yyyy')
                                        ))}</td>
                                        {(payment.amountPaid || payment.amountPaid === 0) ? (
                                            <td>Rs: {payment.amountPaid}/- Amount paid</td>
                                        ) : (
                                            <td>
                                                <a
                                                    href={`/pay?fileNumber=${customer.fileNumber}&customerName=${customer.firstname}&installmentAmount=${payment.installmentAmount}&installmentNumber=${payment.installmentNum}&date=${payment.dueDate}`}
                                                    target="_blank" rel="noreferrer"
                                                >
                                                    Add Payment
                                                </a>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            }
        </div>
    )
}

export default AddPayment;
