import React, { useState } from 'react';
import API from '../myf-api/api.js';
import '../myf-cilent-customer-pendinglist/customerPopUp.css';
import './FinanceBFCalculator.css';
//import Header from '../myf-cilent-header/header.js';

function BFCalculator() {
    const [investmentAmount, setInvestmentAmount] = useState('');
    const [paidAmount, setPaidAmount] = useState('');
    const [singleInstallmentAmount, setSingleInstallmentAmount] = useState('');
    const [fileStatus, setFileStatus] = useState('');
    const [responseMessage, setResponseMessage] = useState(null);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const url = API();

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Prepare data to send to the server
        const data = {
            investmentAmount,
            paidAmount,
            singleInstallmentAmount,
            fileStatus
        };

        try {
            const response = await fetch(`${url}/financeBFCalculator`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const responseData = await response.json();
            setResponseMessage(responseData);
            setPopupVisible(true);
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while processing your request');
        }
    };

    const onClose = () => {
        setPopupVisible(false);
    };

    const handleReset = () => {
        setInvestmentAmount('');
        setPaidAmount('');
        setSingleInstallmentAmount('');
        setFileStatus('');
    };

    document.title = 'BF Calculator - Our Finance';

    return (
        <div>
            {/* <Header /> */}
            <h2>BF Calculator</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Investment Amount:</label>
                    <input
                        type="number"
                        value={investmentAmount}
                        onChange={(e) => setInvestmentAmount(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Paid Amount:</label>
                    <input
                        type="number"
                        value={paidAmount}
                        onChange={(e) => setPaidAmount(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>
                        File Status:
                        <select
                            name="status"
                            value={fileStatus}
                            onChange={(e) => setFileStatus(e.target.value)}
                            required
                        >
                            <option value="">Select an option</option>
                            <option value="Yes">Closed</option>
                            <option value="No">Not Closed</option>
                        </select>
                    </label>
                </div>
                {fileStatus === "No" &&
                    <div>
                        <label>Single Installment Amount:</label>
                        <input
                            type="number"
                            value={singleInstallmentAmount}
                            onChange={(e) => setSingleInstallmentAmount(e.target.value)}
                            required
                        />
                    </div>
                }
                <br></br>
                <div className="button-container">
                    <div className="submit-button">
                        <button type="submit" >Submit</button>
                        <button type="button" onClick={handleReset}>Reset</button>
                    </div>

                </div>


            </form>

            {isPopupVisible && responseMessage && (
                <div className="popup-container">
                    <div className="popup-content">
                        <p>Interest Gain: Rs:{responseMessage.interestGain}/-</p>
                        <p>Balance Fund: Rs:{responseMessage.balanceFund}/-</p>
                        <button onClick={onClose}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BFCalculator;
