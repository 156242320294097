import React, { useState, useEffect } from 'react';
import API from '../myf-api/api.js';
import { useLocation } from 'react-router-dom';

const PaymentDetails = () => {
  const [fileNumber, setFileNumber] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [installmentNumber, setInstallmentNumber] = useState('');
  const [installmentAmount, setInstallmentAmount] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [dateOfPayment, setDateOfPayment] = useState('');
  const [date, setDate] = useState('');
  const [receiptNumber, setReceiptNumber] = useState('');
  const [modeOfPayment, setModeOfPayment] = useState('Cash');
  const [additionalPayments, setAdditionalPayments] = useState([]);
  const url = API();
  const location = useLocation();
  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setFileNumber(searchParams.get('fileNumber') || '');
    setCustomerName(searchParams.get('customerName') || '');
    setInstallmentNumber(searchParams.get('installmentNumber') || '');
    setDate(searchParams.get('date') || '');
    setInstallmentAmount(searchParams.get('installmentAmount') || '');
  }, [location.search]);

  const handleAddPayment = () => {
    setAdditionalPayments([...additionalPayments, { dropdownValue: '', integerValue: '' }]);
  };

  const handleRemovePayment = (index) => {
    const updatedPayments = [...additionalPayments];
    updatedPayments.splice(index, 1);
    setAdditionalPayments(updatedPayments);
  };

  const handlePaymentChange = (index, property, value) => {
    const updatedPayments = [...additionalPayments];
    updatedPayments[index][property] = value;
    setAdditionalPayments(updatedPayments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestBody = {
      fileNumber,
      customerName,
      amountPaid,
      dateOfPayment,
      receiptNumber,
      modeOfPayment,
      installmentNumber,
      additionalPayments,
    };

    try {
      const response = await fetch(`${url}/api/submitPayment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        alert('Payment added successfully');
        window.close();
      } else if (response.status === 400) {
        const data = await response.json();
        alert(`Error: ${data.errorMessage}`);
      } else {
        alert(`Unexpected status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  document.title = 'Pay - Our Finance';

  return (
    <div>

      <h2>Payment Information</h2>

      <div className="payment-info">
        <p>File Number: {fileNumber}</p>
        <p>Customer Name: {customerName}</p>
        <p>Installment Amount: Rs:{installmentAmount}/-</p>
        <p>Installment Date: {date}</p>
      </div>

      <h2>Payment Form</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="fileNumber">File Number:</label>
        <input
          type="text"
          id="fileNumber"
          name="fileNumber"
          value={fileNumber}
          onChange={(e) => setFileNumber(e.target.value)}
          required
        /><br />

        <label htmlFor="customerName">Customer Name:</label>
        <input
          type="text"
          id="customerName"
          name="customerName"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          required
        /><br />

        <label htmlFor="installmentNumber">Installment Number:</label>
        <input
          type="number"
          id="installmentNumber"
          name="installmentNumber"
          value={installmentNumber}
          onChange={(e) => setInstallmentNumber(e.target.value)}
          required
        /><br />

        <label htmlFor="dateOfPayment">Date of Payment:</label>
        <input
          type="date"
          id="dateOfPayment"
          name="dateOfPayment"
          value={dateOfPayment}
          onChange={(e) => setDateOfPayment(e.target.value)}
          required
        /><br />

        <label htmlFor="amountPaid">Amount Paid:</label>
        <input
          type="number"
          id="amountPaid"
          name="amountPaid"
          value={amountPaid}
          onChange={(e) => setAmountPaid(e.target.value)}
          required
        /><br />

        <h2>Additional Payments</h2>
        {additionalPayments.map((payment, index) => (
          <div key={index}>
            <label htmlFor={`dropdown${index}`}>Additional Payment:</label>
            <select
              id={`dropdown${index}`}
              value={payment.dropdownValue}
              onChange={(e) => handlePaymentChange(index, 'dropdownValue', e.target.value)}
            >
              <option value="">Select an option</option>
              <option value="handLoan">HL</option>
              <option value="HL+HLI">HL + HLI</option>
              <option value="handLoanInterest">HLI</option>
              <option value="penalities">PT</option>
              <option value="seizing">seizing</option>
              <option value="travelling">TR</option>
            </select>

            <label htmlFor={`integer${index}`}>Amount:</label>
            <input
              type="number"
              id={`integer${index}`}
              value={payment.integerValue}
              onChange={(e) => handlePaymentChange(index, 'integerValue', e.target.value)}
            />

            <button type="button" onClick={() => handleRemovePayment(index)}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddPayment}>
          Add
        </button>

        <label htmlFor="receiptNumber">Receipt Number:</label>
        <input
          type="text"
          id="receiptNumber"
          name="receiptNumber"
          value={receiptNumber}
          onChange={(e) => setReceiptNumber(e.target.value)}
          required
        /><br />

        <label htmlFor="modeOfPayment">Mode of Payment:</label>
        <select
          id="modeOfPayment"
          name="modeOfPayment"
          value={modeOfPayment}
          onChange={(e) => setModeOfPayment(e.target.value)}
          required
        >
          <option value="Cash">Cash</option>
          <option value="Online">Online</option>
        </select><br />

        <button type="submit">Add Payment</button>
      </form>
    </div>
  );
};

export default PaymentDetails;
