import React, { useState, useEffect } from 'react';
import API from '../myf-api/api.js';
const { format } = require('date-fns');

const SeizedVehicles = () => {
  const [seizedVehicles, setSeizedVehicles] = useState([]);
  const url = API();
  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    const fetchSeizedVehicles = async () => {
      try {
        const response = await fetch(`${url}/seizedVehicles`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
          }
      });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setSeizedVehicles(data);
      } catch (error) {
        console.error('Error fetching seized vehicles:', error);
      }
    };

    fetchSeizedVehicles();
  }, [url, token]);

  return (
    <div>
      <h2>Seized Vehicles</h2>
      {seizedVehicles.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>File Number</th>
              <th>Name</th>
              <th>Bike Model</th>
              <th>Seized Date</th>
              <th>Receipt Number</th>
              <th>Palace</th>
            </tr>
          </thead>
          <tbody>
            {seizedVehicles.map((vehicle, index) => (
              <tr key={vehicle._id}>
                <td>{index + 1}</td>
                <td>{vehicle.fileNumber}</td>
                <td>{vehicle.firstname}</td>
                <td>{vehicle.bikeModel}</td>
                <td>{format(new Date(vehicle.date), "dd-MM-yyyy")}</td>
                <td>{vehicle.receiptNumber}</td>
                <td>{vehicle.palace}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No seized vehicles available.</p>
      )}
    </div>
  );
};

export default SeizedVehicles;
