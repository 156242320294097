import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css';
import API from '../myf-api/api.js'
import Loader from '../components/loader.js';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const url = API()

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await fetch(`${url}/loginValidation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          localStorage.setItem('accessToken', data.accessToken);
          setLoading(false);
          navigate('/home');
        } else {
          setLoading(false);
          alert(data.message);
        }
      } else {
        setLoading(false);
        alert('Login failed');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error during login:', error);
      alert('An error occurred during login');
    }
  };
  useEffect(() => {
    document.title = 'Login';
  }, []);
  return (
    <>
      {loading ? <Loader /> :
        <div className="container">
          <h2>Login</h2>
          <form id="login-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Login</button>
          </form>
        </div>
      }
    </>
  );
}

export default Login;
