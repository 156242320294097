import React, { useState } from 'react';
import API from '../myf-api/api.js';

const ExtraVehicleInvestment = ({ page, fileNumber, bikeDetails}) => {
    const [formData, setFormData] = useState({
        typeOfMoney: '',
        purposeOfMoney: '',
        fileNumber: page === "addPayment" ? fileNumber : '',
        amount: '',
        bikeInformation: page === "addPayment" ? bikeDetails : '',
        date: '',
        receiptNumber: '',
    });
    const [successMessage, setSuccessMessage] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const url = API();
    const token = localStorage.getItem('accessToken');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${url}/addExtraVehicleInvestment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            if (response.status === 200) {
                if (page === "addPayment") {
                    setSuccessMessage('Payment Added successfully');
                    setFormSubmitted(true);
                } else {
                    window.alert(`Vehicle investment details added successfully: ${formData.fileNumber}`);
                    setFormData({
                        typeOfMoney: '',
                        purposeOfMoney: '',
                        fileNumber: '',
                        amount: '',
                        bikeInformation: '',
                        date: '',
                        receiptNumber: '',
                    });
                }
            }
        } catch (error) {
            console.error('Error adding vehicle details:', error);
        }
    };

    return (
        <>
            <h2>Vehicle Investment</h2>
            {successMessage && <p style={{ textAlign: 'center', alignItems: 'center' }}>{successMessage}</p>}
            {!formSubmitted && (
                <form onSubmit={handleSubmit}>
                    <label>
                        Type of Money:
                        <select
                            name="typeOfMoney"
                            value={formData.typeOfMoney}
                            onChange={handleChange}
                        >
                            <option value="">Select an option</option>
                            <option value="investment">Investment</option>
                            <option value="return">Return</option>
                        </select>
                    </label>

                    <label>
                        Purpose of Money:
                        <select
                            name="purposeOfMoney"
                            value={formData.purposeOfMoney}
                            onChange={handleChange}
                        >
                            <option value="">Select an option</option>
                            <option value="repair">Vehicle Repair</option>
                            <option value="collateral">Collateral</option>
                            <option value="Registration">Registration</option>
                            <option value="handLoan">HL</option>
                            <option value="insurance">Insurance</option>
                            <option value="noting">Noting</option>
                            <option value="IC+NO">IC + NO</option>
                            <option value="Lawyer Notice">Lawyer Notice</option>
                            <option value="Vehicle Transport">Vehicle Transport</option>
                            <option value="settlement">settlement</option>
                            <option value="IC+TO">IC + TO</option>
                            <option value="TO">TO</option>
                            <option value="Vehicle Sezing">Vehicle Sezing</option>
                            <option value="Travelling">Travelling</option>
                            <option value="Card">Card</option>
                            {formData.typeOfMoney === 'return' && (
                                <>
                                    <option value="handLoanInterest">HLI</option>
                                    <option value="HL+HLI">HL + HLI</option>
                                    <option value="installment">Installment</option>
                                </>
                            )}
                            <option value="others">Others</option>
                        </select>
                    </label>

                    <label>
                        File Number:
                        <input
                            type="text"
                            name="fileNumber"
                            value={formData.fileNumber}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Amount:
                        <input
                            type="number"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Bike Information:
                        <input
                            type="text"
                            name="bikeInformation"
                            value={formData.bikeInformation}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Date:
                        <input
                            type="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                        />
                    </label>

                    <label>
                        Receipt Number:
                        <input
                            type="text"
                            name="receiptNumber"
                            value={formData.receiptNumber}
                            onChange={handleChange}
                        />
                    </label>

                    <button type="submit">Submit Vehicle Investment</button>
                </form>
            )}
        </>
    );
};

export default ExtraVehicleInvestment;
