const api = () =>{ 
    //const api_url = process.env.NODE_ENV==="development"?process.env.REACT_APP_LOCAL_API_URL:process.env.NODE_ENV==="test"? process.env.REACT_APP_TEST_API_URL:process.env.REACT_APP_PROD_API_URL
    let api_url;
    if (process.env.NODE_ENV==="development"){
        api_url = process.env.REACT_APP_LOCAL_API_URL
    }else if (process.env.NODE_ENV==="production"){
        if(process.env.REACT_APP_ENV==="test"){
            api_url = process.env.REACT_APP_TEST_API_URL
        }else{
            api_url = process.env.REACT_APP_PROD_API_URL
        }
    }
    return api_url
}

export default api
