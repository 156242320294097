import React, { useState } from 'react';
//import Header from '../myf-cilent-header/header.js';
import SeizedVehicles from './seizedVehicle.js';
import ExtraVehicleInvestment from './extraVehicleInvestment.js';
import API from '../myf-api/api.js';

const VehicleManagement = ({ userRole }) => {
    const [formData, setFormData] = useState({
        fileNumber: '',
        firstname: '',
        date: '',
        bikeModel: '',
        receiptNumber: '',
        palace: ''
    });

    const [activeForm, setActiveForm] = useState(null);
    const url = API();
    const token = localStorage.getItem('accessToken');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleButtonClick = (formType) => {
        setFormData({
            fileNumber: '',
            firstname: '',
            date: '',
            bikeModel: '',
            receiptNumber: '',
            palace: ''
        });
        if (formType === activeForm) {
            setActiveForm(null);
          } else {
            setActiveForm(formType);
        }
    };

    const handleSeizedFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${url}/addVehicle`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            if (response.status === 200) {
                window.alert(`Vehicle details added successfully: ${formData.fileNumber}`);
                setFormData({
                    fileNumber: '',
                    firstname: '',
                    date: '',
                    bikeModel: '',
                    receiptNumber: '',
                    palace: ''
                });
            }
        } catch (error) {
            console.error('Error adding vehicle details:', error);
        }
    };

    document.title = 'Vehicle Management - Our Finance';

    const renderForm = () => {
        switch (activeForm) {
            case 'addSeizedVehicle':
                return (
                    <>
                        <h2>Add Seized Vehicle</h2>
                        <form onSubmit={handleSeizedFormSubmit}>
                            <label>
                                File Number:
                                <input
                                    type="text"
                                    name="fileNumber"
                                    value={formData.fileNumber}
                                    onChange={handleChange} />
                            </label>

                            <label>
                                Name:
                                <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} />
                            </label>

                            <label>
                                Date:
                                <input type="date" name="date" value={formData.date} onChange={handleChange} />
                            </label>

                            <label>
                                Bike Information:
                                <input
                                    type="text"
                                    name="bikeModel"
                                    value={formData.bikeModel}
                                    onChange={handleChange} />
                            </label>

                            <label>
                                Receipt Number:
                                <input
                                    type="text"
                                    name="receiptNumber"
                                    value={formData.receiptNumber}
                                    onChange={handleChange} />
                            </label>

                            <label>
                                Palace:
                                <input type="text" name="palace" value={formData.palace} onChange={handleChange} />
                            </label>

                            <button type="submit">Submit Seized Vehicle</button>
                        </form>
                    </>
                );
            case 'extraVehicleInvestment':

                return (
                    <ExtraVehicleInvestment/>
                );
            case 'seizedVehicles':

                return (
                    <SeizedVehicles/>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {/* <Header /> */}
            <h2>Vehicle Management</h2>
            <div>
                <button onClick={() => handleButtonClick('addSeizedVehicle')}>Add Seized Vehicle</button>
                {(userRole === 'Owner') && <button onClick={() => handleButtonClick('extraVehicleInvestment')}>Vehicle Investment</button>}
                <button onClick={() => handleButtonClick('seizedVehicles')}>Seized Vehicles</button>
            </div>
            {renderForm()}
        </>
    );
};

export default VehicleManagement;
