import React from 'react';
import './customerPopUp.css'
const { format } = require('date-fns');

const CustomerPopup = ({ customer, onClose }) => {
    return (
        <div className="popup-container">
            <div className="popup-content">
                <h2>{customer.firstname}'s Details</h2>
                <p>File Number: {customer.fileNumber}</p>
                <p>First Name: {customer.firstname}</p>
                <p>Installemnt Date: {format(new Date(customer.takenDate), 'dd-MM-yyyy')}</p>
                <p>Hand Loan(HL): Rs:{customer.handLoan}/-</p>
                <p>Installment Amount: Rs:{customer.amount}/-</p>
                <p>Installments: {customer.installments}</p>
                <p>Single Installemnt Amount: Rs:{customer.perInstallmentAmount}/-</p>
                <p>Penalties: {customer.totalPenalties}</p>
                <p>Bike Model: {customer.bikeModel}</p>
                <p>Phone Number: {customer.phoneNumber}</p>
                <p>Address: {customer.address}</p>
                <p>Guarantor: {customer.nominee}</p>
                <p>Guarantor Phone Number: {customer.guarantorAddressPhoneNumber}</p>
                <p>Aadhar: {customer.aadhar}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default CustomerPopup;
