import React, { useState } from "react";
//import Header from "../myf-cilent-header/header.js";
import "./customer.css";
//import "./table.css"
import API from "../myf-api/api.js";
import Loader from "../components/loader.js";
import "../myf-cilent-customer-pendinglist/customerPopUp.css";
import UpdateFileStatus from "./updateCustomerDetails.js";
const { format } = require("date-fns");

const CustomerInstallmentPage = ({ userRole }) => {
  const [fileNumber, setFileNumber] = useState("");
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const url = API();
  const token = localStorage.getItem("accessToken");
  const [isPopupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const onClose = () => {
    setPopupVisible(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const customerResponse = await fetch(
        `${url}/api/customerInstallmentsDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ fileNumber }),
        }
      );

      const customerData = await customerResponse.json();
      setCustomer(customerData);

      const seizedVehicleResponse = await fetch(
        `${url}/seizedVehicles?fileNumber=${fileNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!seizedVehicleResponse.ok) {
        throw new Error("Network response was not ok");
      }
      const seizedVehicleData = await seizedVehicleResponse.json();

      const additionalInvestment = await fetch(
        `${url}/addExtraVehicleInvestment?fileNumber=${fileNumber}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const additionalInvestmentData = await additionalInvestment.json();

      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        seizedVehicle: seizedVehicleData,
        additionalInvestment: additionalInvestmentData,
      }));

      setLoading(false);
    } catch (error) {
      console.error("Error fetching seized vehicles:", error);
    }
  };

  document.title = "Customer Details - Our Finance";

  return (
    <div>
      {/* <Header /> */}
      <h2>Customer Installment Page</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter File number"
          value={fileNumber}
          onChange={(e) => setFileNumber(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>

      {loading ? (
        <Loader />
      ) : (
        customer && (
          <div>
            <h2>Customer Details</h2>
            <p>File Number: {customer.customer.fileNumber}</p>
            <p>First Name: {customer.customer.firstname}</p>
            <p>
              Installment Date:{" "}
              {format(new Date(customer.takenDatedate), "dd-MM-yyyy")}
            </p>
            <p>Bike Model: {customer.customer.bikeModel}</p>
            <p>Hand Loan(HL): {customer.customer.handLoan}</p>
            <div className="red-text">
              {customer.updatedInstallments.map((installment) =>
                installment.additionalPayments.length > 0 &&
                (installment.additionalPayments[0].handLoan !== undefined ||
                  installment.additionalPayments[0].handLoanInterest !==
                    undefined) ? (
                  <p key={installment.installmentNum}>
                    {installment.additionalPayments[0].handLoan
                      ? `HL Paid: Rs:${installment.additionalPayments[0].handLoan}/-`
                      : ""}
                    {installment.additionalPayments[0].handLoanInterest
                      ? `HLI Paid: Rs:${installment.additionalPayments[0].handLoanInterest}/-`
                      : ""}
                    {installment.dateOfPaid
                      ? ` (Paid on: ${format(
                          new Date(installment.dateOfPaid),
                          "dd-MM-yyyy"
                        )}`
                      : ""}
                    {installment.receiptNumber
                      ? `, Receipt Number: ${installment.receiptNumber})`
                      : ""}
                  </p>
                ) : (
                  ""
                )
              )}
            </div>
            <p>Amount: Rs:{customer.customer.amount}/-</p>
            <p>Installments: {customer.customer.installments}</p>
            <p>Nominee: {customer.customer.nominee}</p>
            <p>Aadhar: {customer.customer.aadhar}</p>
            <p>Total Penalties: {customer.totalPenalties}</p>
            <>
              {customer.customer.fileStatus === "closed" && (
                <div className="file-status-container closed">
                  <p className="file-status">Closed</p>
                </div>
              )}
            </>
            {customer.seizedVehicle && (
              <div className="red-text">
                <h2>Seizing Details</h2>
                <h4>
                  Seized Date:{" "}
                  {format(new Date(customer.seizedVehicle.date), "dd-MM-yyyy")}
                </h4>
                {customer.seizedVehicle.receiptNumber !== "" && (
                  <h4>
                    Receipt Number: {customer.seizedVehicle.receiptNumber}
                  </h4>
                )}
                <h4>Vehicle Palace: {customer.seizedVehicle.palace}</h4>
              </div>
            )}

            {customer.additionalInvestment.length > 0 && (
              <div>
                <h2>Additional Investment</h2>
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Type of Money</th>
                      <th>Purpose Of Money</th>
                      <th>Payment Date</th>
                      <th>Amount</th>
                      <th>Receipt Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customer.additionalInvestment.map((payments, index) => (
                      <tr key={payments.typeOfMoney}>
                        <td>{index + 1}</td>
                        <td>{payments.typeOfMoney}</td>
                        <td>{payments.purposeOfMoney}</td>
                        <td>{format(new Date(payments.date), "dd-MM-yyyy")}</td>
                        <td>Rs:{payments.amount}/-</td>
                        <td>{payments.receiptNumber}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {(userRole === 'Owner') && <button onClick={togglePopup}>Update Customer Details</button>}
            {isPopupVisible && (
              <div className="popup-container">
                <div className="popup-content">
                  <UpdateFileStatus customerDetails={customer.customer} />
                  <br />
                  <button onClick={onClose}>Close</button>
                </div>
              </div>
            )}

            <h2>Installment Schedule</h2>
            <div className="table-container">
              <table className="centered-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Month</th>
                    <th>Due Date</th>
                    <th>Due Amount</th>
                    <th>Paid Date</th>
                    <th>Paid Amount</th>
                    <th>Receipt Number</th>
                    <th>No. of Penalties</th>
                  </tr>
                </thead>
                <tbody>
                  {customer.updatedInstallments.map((installment) => (
                    <tr key={installment.installmentNum}>
                      <td>{installment.installmentNum}</td>
                      <td style={{ whiteSpace: 'nowrap' }} >{installment.month}</td>
                      <td style={{ whiteSpace: 'nowrap' }} >
                        {format(new Date(installment.dueDate), "dd-MM-yyyy")}
                      </td>
                      <td>Rs:{installment.installmentAmount}/-</td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {installment.dateOfPaid
                          ? format(new Date(installment.dateOfPaid), "dd-MM-yyyy")
                          : ""}
                      </td>
                      <td>
                        <div>
                          {installment.amountPaid !== null
                            ? `Rs:${
                                installment.amountPaid -
                                installment.totalAdditionalPayments
                              }/-`
                            : null}
                        </div>
                        {installment.additionalPayments.length > 0 && (
                          <>
                            <div>
                              {installment.additionalPayments[0].penalities !==
                              undefined
                                ? `PT: Rs:${installment.additionalPayments[0].penalities}/-`
                                : null}
                            </div>
                            <div>
                              {installment.additionalPayments[0].seizing !==
                              undefined
                                ? `seizing: Rs:${installment.additionalPayments[0].seizing}/-`
                                : null}
                            </div>
                            <div>
                              {installment.additionalPayments[0].travelling !==
                              undefined
                                ? `TR: Rs:${installment.additionalPayments[0].travelling}/-`
                                : null}
                            </div>
                          </>
                        )}
                      </td>
                      <td>{installment.receiptNumber}</td>
                      <td>{installment.penalties}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default CustomerInstallmentPage;
