//import Header from '../myf-cilent-header/header.js';
import React, { useState } from 'react';
import API from '../myf-api/api.js';

function AddCustomer() {
    const [formData, setFormData] = useState({
        fileNumber: '',
        firstname: '',
        fathername: '',
        date: '',
        bikeType: '',
        bikeModel: '',
        amount: '',
        installments: '',
        nominee: '',
        guarantorfathername: '',
        aadhar: '',
        address: '',
        lineOfBussiness: '',
        phoneNumber: '',
        handLoan: '',
        guarantorAddress: '',
        guarantorAddressPhoneNumber: '',
        agentName: '',
    });
    const url = API();
    const token = localStorage.getItem('accessToken');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${url}/api/addCustomerDetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        });

        if (response.status === 200) {
            window.alert(`Customer added successfully! File Number: ${formData.fileNumber}`);
            setFormData({
                fileNumber: '',
                firstname: '',
                fathername: '',
                date: '',
                bikeType: '',
                bikeModel: '',
                amount: '',
                installments: '',
                nominee: '',
                guarantorfathername: '',
                aadhar: '',
                address: '',
                lineOfBussiness: '',
                phoneNumber: '',
                handLoan: '',
                guarantorAddress: '',
                guarantorAddressPhoneNumber: '',
                agentName: '',
            });
        } else {
            const data = await response.json();
            window.alert(`Error: ${data.message}`);
        }
    };

    document.title = 'Add Customer - Our Finance';

    return (
        <div>
            {/* <Header /> */}
            <h2>Add Customer Details</h2>

            <form id="customer-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="fileNumber">File Number:</label>
                    <input type="text" id="fileNumber" name="fileNumber" value={formData.fileNumber} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Customer Name:</label>
                    <input type="text" id="name" name="firstname" value={formData.firstname} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="fathername">Father Name:</label>
                    <input type="text" id="fathername" name="fathername" value={formData.fathername} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Customer Address:</label>
                    <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Customer Phone Number:</label>
                    <input type="text" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="aadhar">Customer Aadhar Number:</label>
                    <input type="text" id="aadhar" name="aadhar" value={formData.aadhar} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="date">Date of Finance:</label>
                    <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} required />
                </div>
                <div>
                    <label htmlFor="date">Bike Type:</label>
                    <select
                        name="bikeType"
                        value={formData.bikeType}
                        onChange={handleChange}
                    >
                        <option value="">Select an option</option>
                        <option value="new">New</option>
                        <option value="old">Old</option>
                    </select>

                </div>
                <div className="form-group">
                    <label htmlFor="bike-model">Bike Model:</label>
                    <input type="text" id="bike-model" name="bikeModel" value={formData.bikeModel} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="handLoan">Hand Loan(HL):</label>
                    <input type="number" id="handLoan" name="handLoan" value={formData.handLoan} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="amount">Installment Amount:</label>
                    <input type="number" id="amount" name="amount" value={formData.amount} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="installments">No of Installments:</label>
                    <input type="number" id="installments" name="installments" value={formData.installments} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="nominee">Guarantor Name:</label>
                    <input type="text" id="nominee" name="nominee" value={formData.nominee} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="guarantorfathername">Guarantor Father Name:</label>
                    <input type="text" id="guarantorfathername" name="guarantorfathername" value={formData.guarantorfathername} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="guarantorAddress">Guarantor Address:</label>
                    <input type="text" id="guarantorAddress" name="guarantorAddress" value={formData.guarantorAddress} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Guarantor Phone Number:</label>
                    <input type="text" id="guarantorAddressPhoneNumber" name="guarantorAddressPhoneNumber" value={formData.guarantorAddressPhoneNumber} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="lineOfBussiness">Line of Bussiness:</label>
                    <input type="text" id="lineOfBussiness" name="lineOfBussiness" value={formData.lineOfBussiness} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="agentName">File Agent:</label>
                    <input type="text" id="agentName" name="agentName" value={formData.agentName} onChange={handleChange} required />
                </div>
                <button type="submit" name="addCustomer">Add Customer</button>
            </form>
        </div>
    );
}

export default AddCustomer;
