import React, { useState } from "react";
import API from "../myf-api/api.js";

const UpdateFileStatus = ({ customerDetails }) => {
  const [fileStatus, setFileStatus] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const token = localStorage.getItem("accessToken");
  const url = API();

  const handleChange = (event) => {
    setFileStatus(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const customerResponse = await fetch(`${url}/api/updateCustomerDetails`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fileNumber: customerDetails.fileNumber,
          fileStatus,
        }),
      });
      
      if (customerResponse.status === 200) {
        const data = await customerResponse.json();
        setMessage(data.message);
      } else {
        setError("Failed to update file status");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>Update File Status</h2>
      {message ? (
        <div className="message">{message}</div>
      ) : (
        <>
          {error && <div className="error">{error}</div>}
          <form onSubmit={handleSubmit}>
            <label>
              File Status:
              <select value={fileStatus} onChange={handleChange}>
                <option value="">Select an option</option>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
              </select>
            </label>
            <button type="submit">Submit</button>
          </form>
        </>
      )}
    </div>
  );
};

export default UpdateFileStatus;
